*::placeholder {
    font-size: 14px;
    color: darkgray !important;
}

.font-theme {
    font-family: "Montserrat, Sans-serif", sans-serif;
}

.border-theme {
    border: 1px solid #b4b4b4;
}

.bg-theme {
    &.green { background-color: forestgreen; }
    &.darkblue { background-color: darkslateblue; }
    &.darkpurple { background-color: #4434a7; }
    &.yellow { background-color: #FDD633; }
    &.blue { background-color: #002ABB; }
    &.midnightblue { background-color: midnightblue; }
}

.text-theme {
    &.green { color: forestgreen; }
    &.darkblue { color: darkslateblue; }
    &.darkpurple { color: #4434a7; }
    &.yellow { color: #FDD633; }
    &.blue { color: #002ABB; }
}

.paragraph-theme {
    color: #414141;
}

.heading-theme {
    color: #002a8b;
}

.link-theme {
    &.green {
        color: forestgreen;
        &:hover {
            color: #3DB43DFF;
        }
    }

    &.darkblue {
        color: darkslateblue;
        &:hover {
            color: #6a59d0;
        }
    }

    &.darkpurple {
        color: #4434a7;
        &:hover {
            color: #6a59d0;
        }
    }
}

.background-theme {
    background-color: black;
}

.btn-theme {
    border-radius: 8px;
    padding: 4px 13px;

    &.darkblue {
        background: darkslateblue;
        border: 1px solid black;
        color: white;

        &.inverted {
            background: ghostwhite;
            color: darkslateblue;
            border: 1px solid darkslateblue;
        }
    }

    &.green {
        background: forestgreen;
        border: 1px solid forestgreen;
        color: white;

        &.inverted {
            background: white;
            color: forestgreen;
            border: 1px solid forestgreen;
        }
    }

    &.darkpurple {
        background-color: #4434a7;
        border: 1px solid #4434a7;
    }

    &.yellow {
        background-color: #FDD633;
        color: #002ABB;
    }

    &.blue {
        background-color: midnightblue;
        color: white;

        &.inverted {
            background: white;
            color: midnightblue;
            border: 1px solid midnightblue;
        }
    }
}
