.lime-green {
  color: limegreen;
}

.custom-green {
  color: #6ce700;
}

.bright-yellow {
  color: #ffee00;
}

.rebecca-purple {
  color: rebeccapurple;
}

