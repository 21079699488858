*::placeholder {
  font-size: 14px;
  color: darkgray !important;
}

.font-theme {
  font-family: "Montserrat, Sans-serif", sans-serif;
}

.border-theme {
  border: 1px solid #b4b4b4;
}

.bg-theme.green {
  background-color: forestgreen;
}
.bg-theme.darkblue {
  background-color: darkslateblue;
}
.bg-theme.darkpurple {
  background-color: #4434a7;
}
.bg-theme.yellow {
  background-color: #FDD633;
}
.bg-theme.blue {
  background-color: #002ABB;
}
.bg-theme.midnightblue {
  background-color: midnightblue;
}

.text-theme.green {
  color: forestgreen;
}
.text-theme.darkblue {
  color: darkslateblue;
}
.text-theme.darkpurple {
  color: #4434a7;
}
.text-theme.yellow {
  color: #FDD633;
}
.text-theme.blue {
  color: #002ABB;
}

.paragraph-theme {
  color: #414141;
}

.heading-theme {
  color: #002a8b;
}

.link-theme.green {
  color: forestgreen;
}
.link-theme.green:hover {
  color: #3db43d;
}
.link-theme.darkblue {
  color: darkslateblue;
}
.link-theme.darkblue:hover {
  color: #6a59d0;
}
.link-theme.darkpurple {
  color: #4434a7;
}
.link-theme.darkpurple:hover {
  color: #6a59d0;
}

.background-theme {
  background-color: black;
}

.btn-theme {
  border-radius: 8px;
  padding: 4px 13px;
}
.btn-theme.darkblue {
  background: darkslateblue;
  border: 1px solid black;
  color: white;
}
.btn-theme.darkblue.inverted {
  background: ghostwhite;
  color: darkslateblue;
  border: 1px solid darkslateblue;
}
.btn-theme.green {
  background: forestgreen;
  border: 1px solid forestgreen;
  color: white;
}
.btn-theme.green.inverted {
  background: white;
  color: forestgreen;
  border: 1px solid forestgreen;
}
.btn-theme.darkpurple {
  background-color: #4434a7;
  border: 1px solid #4434a7;
}
.btn-theme.yellow {
  background-color: #FDD633;
  color: #002ABB;
}
.btn-theme.blue {
  background-color: midnightblue;
  color: white;
}
.btn-theme.blue.inverted {
  background: white;
  color: midnightblue;
  border: 1px solid midnightblue;
}

