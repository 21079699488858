.dashboard-nav {
    border: 1px solid lightgray;
    border-radius: 6px;
    list-style-type: none;
    padding: 0.7rem;
    margin-bottom: 1rem;

    &.active {
        background-color: #ffc107; //#0d6efd;
        color: black;
    }
}

.dashboard-sub-nav {
    padding-left: 2rem;

    li {
        padding-left: 1.5rem;
        list-style-type: none;
        margin-bottom: 1rem;

        &.active {
            border-left: 3px solid black;
            color: #0d6efd;
            margin-left: -3px;
        }
        &:hover {
            &:not(.active) {
                color: steelblue;
            }
        }
    }
}

.user-table {
    thead {
        tr {
            border: none;

            th {
                border-color: lightgrey;
                #border-top: 2px solid lightgrey;
                color: grey;
                font-weight: bold;
            }
        }
    }

    tbody {
        tr {
            border: none;
            td {
                border-top: 1px solid lightgrey;
                border-bottom: 1px solid lightgrey;
                padding: 15px 10px;
            }
        }
    }
}

.hr {
    margin-bottom: 18px !important;
}