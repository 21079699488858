.onboarding {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
}

.page-display {
    bottom: 10%;
    position: relative;
    text-align: center;
}