.dashboard-nav {
  border: 1px solid lightgray;
  border-radius: 6px;
  list-style-type: none;
  padding: 0.7rem;
  margin-bottom: 1rem;
}
.dashboard-nav.active {
  background-color: #ffc107;
  color: black;
}

.dashboard-sub-nav {
  padding-left: 2rem;
}
.dashboard-sub-nav li {
  padding-left: 1.5rem;
  list-style-type: none;
  margin-bottom: 1rem;
}
.dashboard-sub-nav li.active {
  border-left: 3px solid black;
  color: #0d6efd;
  margin-left: -3px;
}
.dashboard-sub-nav li:hover:not(.active) {
  color: steelblue;
}

.user-table thead tr {
  border: none;
}
.user-table thead tr th {
  border-color: lightgrey;
  #border-top: 2px solid lightgrey;
  color: grey;
  font-weight: bold;
}
.user-table tbody tr {
  border: none;
}
.user-table tbody tr td {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  padding: 15px 10px;
}

.hr {
  margin-bottom: 18px !important;
}

