.hover-orange:hover {
  background-color: orange;
  color: white !important;
  border-radius: 5px;
}
.hover-orange.active {
  background-color: orange;
  color: white !important;
  border-radius: 5px;
}

