.banner-truck {
  background-image: url("./../images/skipbin_trucks.jpeg");
}

.logo-center {
  display: grid;
  place-items: center;
  align-content: center;
  background-color: ghostwhite;
}

