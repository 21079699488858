.payment-form {
  margin: 30px;
}

.card-element-container {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

